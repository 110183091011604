import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { GlobalStyle } from '@/styles/global'
import { State } from '@/store'
import Loading from '@/layouts/Loading'
import Footer from '@/components/Footer'

interface Props {
  children: JSX.Element | JSX.Element[]
}
export const BlogLayout: React.FC<Props> = (props): JSX.Element => {
  const sessionStore = useSelector((state: State) => state.session)

  return (
    <StyledComponent>
      <GlobalStyle />
      {!sessionStore.session && <Loading />}
      <main className='main'>{props.children}</main>
      <Footer />
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  .main {
    width: 100%;
    margin: auto;
  }
`
