import React, { useEffect } from 'react'

interface Props {}
export const GoogleAdsense: React.FC<Props> = (): JSX.Element => {
  useEffect(() => {
    console.log('GoogleAdsense NODE_ENV=', process.env.NODE_ENV)
    window.adsbygoogle = window.adsbygoogle || []
    window.adsbygoogle.push({})
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <ins
        className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-client='ca-pub-5942117920715661'
        data-ad-slot='6630791371'
        data-ad-format='auto'
        data-full-width-responsive='true'
      />
    </div>
  )
}
