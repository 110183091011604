import * as React from 'react'
import styled from 'styled-components'

interface Props {
  value: string
}
const Tag: React.FC<Props> = (props): JSX.Element => {
  return (
    <StyledComponent>
      <a href='/' className='tag'>
        {props.value}
      </a>
    </StyledComponent>
  )
}
export default Tag

const StyledComponent = styled.div`
  display: inline-block;
  min-width: 50px;
  height: 22px;
  border: 1px solid #2cb696;
  border-radius: 4px;
  margin: 4px;
  padding: 0 12px;

  .tag {
    display: block;
    font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
      'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
      Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-size: 14px;
    font-weight: 800;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    line-height: 22px;
    text-align: center;
    color: #2cb696;
    text-decoration: none;
    pointer-events: none;
  }

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 768px) {
    padding: 0 6px;
    height: 18px;
    min-width: 35px;

    .tag {
      font-size: 11px;
      line-height: 18px;
    }
  }
  @media screen and (max-width: 480px) {
  }
`
