import * as React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { BlogLayout } from '@/layouts/blog'
import SEO from '@/components/seo'
import { ProfileImage } from '@/components/atoms/images/ProfileImage'
import Tag from '@/components/atoms/Tag'
import { Markdown } from '@/components/Markdown'
import { GoogleAdsense } from '@/components/GoogleAdsense'

interface Props {
  data: any
}
const Blog: React.FC<Props> = (props): JSX.Element => {
  const markdown = props.data.markdownRemark || {}
  const title = markdown.frontmatter.title || ''
  const discription = markdown.frontmatter.discription || ''

  return (
    <BlogLayout>
      <SEO title={title} description={discription} />
      <StyledComponent>
        <div className='media-wrap'>
          <div className='header'>
            <h1 className='title'>{title}</h1>
            <div className='header__infos'>
              <Link to='/' className='profile'>
                <div className='profile__image'>
                  <ProfileImage />
                </div>
                <span className='profile__text'>taikishiino</span>
              </Link>
              <i>{markdown.frontmatter.date}</i>
            </div>
          </div>
          <div>
            {markdown.frontmatter.tags && markdown.frontmatter.tags.map((tag: string, i: number) => (
              <Tag value={tag} key={i} />
            ))}
          </div>
          <div className='content'>
            <Markdown html={markdown.html} />
          </div>
        </div>
        {title.length > 0 && (
          <div className='google_adsense'>
            <GoogleAdsense />
          </div>
        )}
      </StyledComponent>
    </BlogLayout>
  )
}
export default Blog

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        discription
        tags
      }
    }
  }
`

const StyledComponent = styled.div`
  margin: 0 auto;
  width: 720px;

  .header {
    margin: 40px 0;
    text-align: center;

    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.4;
    }

    &__infos {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      color: gray;

      .profile {
        display: flex;
        align-items: center;
        color: gray;

        &__image {
          width: 24px;
          height: 24px;
          .gatsby-image-wrapper {
            border-radius: 24px;
            box-shadow: 1px 1px 1px 1px #c1d6ec;
          }
        }
        &__text {
          font-size: 16px;
          margin-left: 8px;
        }
      }

      i {
        font-size: 14px;
        font-style: oblique;
        margin-left: 48px;
      }
    }
  }

  .content {
    font-family: 'Noto Sans Japanese', '游ゴシック体', 'Yu Gothic', YuGothic,
      'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
      Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    font-weight: 300;
  }

  .google_adsense {
    margin-top: 64px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;

    .media-wrap {
      padding: 0 24px;
    }
    .header {
      .title {
        font-size: 20px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .media-wrap {
      padding: 0 20px;
    }
  }
  @media screen and (max-width: 480px) {
  }
`
